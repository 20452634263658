import { Refine } from "@pankod/refine-core";
import {
  notificationProvider,
  Layout,
  ReadyPage,
  ErrorComponent,
} from '@pankod/refine-antd';
import "@pankod/refine-antd/dist/styles.min.css";
import routerProvider from "@pankod/refine-react-router-v6";
import { DataProvider } from "@pankod/refine-strapi-v4";
import {TimbratureList} from './pages';
import {authProvider, axiosInstance} from './authProvider';
import {API_URL} from './constants';
import {Login} from './pages/login';
import {Header} from './components/layout';
import { useTranslation } from 'react-i18next';
import {UserCreate, UserList} from './pages/users';
import {UserEdit} from './pages/users/edit';
import {ParametriCreate, ParametriEdit, ParametriList} from './pages/parametris';
import {SediCreate, SediEdit, SediList} from './pages/sedis';
const { Link } = routerProvider;
function App() {
    const { t, i18n } = useTranslation();

    const i18nProvider = {
        translate: (key: string, params: object) => t(key, params),
        changeLocale: (lang: string) => i18n.changeLanguage(lang),
        getLocale: () => i18n.language,
    };
  return (
    <Refine
        disableTelemetry={true}
      notificationProvider={notificationProvider}
      Layout={Layout}
      ReadyPage={ReadyPage}
      catchAll={<ErrorComponent />}
      authProvider={authProvider}
      routerProvider={routerProvider}
      dataProvider={DataProvider(API_URL + '/api', axiosInstance)}
      LoginPage={Login}
      i18nProvider={i18nProvider}
      Title={({ collapsed }) => (
          <Link to="/">
            {collapsed ? (
                <img
                    src="/images/logo_collapsed.png"
                    alt="Sonea"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "8px 16px",
                    }}
                />
            ) : (
                <img
                    src="/images/logo.jpeg"
                    alt="Sonea - La Brillante"
                    style={{
                      width: 200,
                        height: 90,
                      padding: "12px 24px",
                    }}
                />
            )}
          </Link>
      )}
      Header={Header}
      resources={[
          { name: "timbratures", options: {label: 'Timbrature'}, list: TimbratureList },
          { name: "users", options: {label: 'Utenti'}, list: UserList, edit: UserEdit, create: UserCreate, canDelete: true },
          // { name: "parametris", options: {label: 'Parametri'}, list: ParametriList, edit: ParametriEdit, create: ParametriCreate, canDelete: true },
          { name: "sedis", options: {label: 'Sedi Lavorative'}, list: SediList, edit: SediEdit, create: SediCreate, canDelete: true },
      ]}
    />
  );
}

export default App;
