import {
    List,
    TextField,
    TagField,
    DateField,
    Table,
    useTable, Space, EditButton, DeleteButton
} from '@pankod/refine-antd';

import {ISedi, IUser} from 'interfaces';

export const UserList: React.FC = () => {
    const { tableProps, filters, setFilters } = useTable<IUser>({
        metaData: {
            populate: "*",
        },
    });

    const handleChange = (d: any) => {
        console.log(d);
        setFilters(
        [{
            field: 'user][id]',
            operator: 'eq',
            value: d
        }])
    }

    return (
        <List title={'Utenti'}>
            <Table {...tableProps} size={"small"} rowKey="id">
                <Table.Column
                    dataIndex="nome"
                    title="Nome"
                    render={(value) => <TagField color={"green"} value={value} />}
                />
                <Table.Column
                    dataIndex="cognome"
                    title="Cognome"
                    render={(value) => <TagField color={"red"} value={value} />}
                />
                <Table.Column
                    dataIndex="email"
                    title="Email"
                />
                <Table.Column
                    dataIndex="matricola"
                    title="Matricola"
                />
                {/*<Table.Column*/}
                {/*    dataIndex="latitude"*/}
                {/*    title="Latitudine"*/}
                {/*/>*/}
                {/*<Table.Column*/}
                {/*    dataIndex="longitude"*/}
                {/*    title="Longitudine"*/}
                {/*/>*/}
                <Table.Column
                    dataIndex="checkDistance"
                    title="Controllo Distanza"
                    render={(value) => <TagField color={value ? "green" : "warning"} value={value ? 'SI' : 'NO'} />}
                />
                <Table.Column
                    dataIndex="gruppo"
                    title="Gruppo"
                    render={(value) => <TagField color={"blue"} value={value?.descrizione} />}
                />
                <Table.Column
                    dataIndex="sedis"
                    title="Sedi"
                    render={(value) => {
                        return (
                            <TextField
                                value={
                                    value?.map((item: ISedi) =>
                                        <TagField key={item.id} color={"blue"} value={item?.nomeSede} />
                                    )
                                }
                            />
                        );
                    }}
                />
                <Table.Column<IUser>
                    title="Actions"
                    dataIndex="actions"
                    render={(_text, record): React.ReactNode => {
                        return (
                            <Space>
                                <EditButton
                                    size="small"
                                    recordItemId={record.id}
                                    hideText
                                />
                                <DeleteButton
                                    size="small"
                                    recordItemId={record.id}
                                    hideText
                                />
                            </Space>
                        );
                    }}
                />
            </Table>
        </List>
    );
};
