import {
    List,
    TagField,
    Table,
    useTable, Space, EditButton, DeleteButton, notificationProvider
} from '@pankod/refine-antd';
import {ISedi} from 'interfaces';

export const SediList: React.FC = () => {
    const { tableProps, tableQueryResult } = useTable<ISedi>({
        resource: 'sedis',
    });

    return (
        <List title={'Parametri'}>
            <Table {...tableProps} size={"small"} rowKey="id">
                <Table.Column
                    title="Nome Sede"
                    dataIndex="nomeSede"
                    render={(value) => <TagField color={"blue"} value={value} />}
                />
                <Table.Column
                    title="Latitudine"
                    dataIndex="latitudine"
                    render={(value) => <TagField color={"green"} value={value} />}
                />
                <Table.Column
                    dataIndex="longitudine"
                    title="Longitudine"
                    render={(value) => <TagField color={"red"} value={value} />}
                />
                <Table.Column<ISedi>
                    title="Actions"
                    dataIndex="actions"
                    render={(_text, record): React.ReactNode => {
                        return (
                            <Space>
                                <EditButton
                                    size="small"
                                    recordItemId={record.id}
                                    hideText
                                />
                                <DeleteButton
                                    size="small"
                                    recordItemId={record.id}
                                    hideText
                                />
                            </Space>
                        );
                    }}
                />
            </Table>
        </List>
    );
};
