import {
    useForm,
    Form,
    Input,
    Select,
    Edit,
    useSelect, Checkbox, Switch,
} from '@pankod/refine-antd';
import {ISedi, IUser} from 'interfaces';
import {useEffect, useState} from 'react';

interface sedi {
    label: string;
    value: number;
}
export const UserEdit: React.FC = () => {
    const { formProps, saveButtonProps, queryResult, formLoading } = useForm<IUser>({
        metaData: {
            populate: "*",
        },
    });


    const [sedi, setSedi] = useState([]);
    const [requirePassword, setRequirePassword] = useState(false);
    const { selectProps: gruppoSelectProps } = useSelect<IUser>({
        resource: "gruppos",
        optionValue: 'id',
        optionLabel: 'descrizione',
        defaultValue: queryResult?.data?.data?.gruppo?.id,
    });

    const { selectProps: sediSelectProps } = useSelect<IUser>({
        resource: "sedis",
        optionValue: 'id',
        optionLabel: 'nomeSede',
    });


    useEffect( () => {
        const sed: any = [];
        queryResult?.data?.data?.sedis?.map( s => {
            sed.push({label: s.nomeSede, value: s.id});
        })
        formProps.form?.setFields([{name: ["sedis"], value: sed}])
    }, [formLoading])

    const onChange = (e: any) => {
        if (e) {
            setRequirePassword(true);
        } else {
            setRequirePassword(false);
        }
    };

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical">
                <Form.Item
                    label="Nome"
                    name="nome"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Cognome"
                    name="cognome"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    hidden={false}
                    label="Matricola"
                    name="matricola"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Controllo Distanza"
                    name={"checkDistance"}
                    valuePropName={'checked'}
                >
                    <Switch />
                </Form.Item>
                <Form.Item
                    label="Cambia Password"
                    name={"cp"}
                >
                    <Switch onChange={(e: any) => onChange(e)} />
                </Form.Item>
                <Form.Item hidden={!requirePassword}
                    label="Password"
                    name="password"
                    rules={[{ required: requirePassword, message: 'Please add a password' },
                        { min: 8, message: 'Password must have a minimum length of 8' },
                        {
                            pattern: new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$'),
                            message: 'Password must contain at least one lowercase letter, uppercase letter, number, and special character'
                        }]}
                >
                    <Input type={"password"}/>
                </Form.Item>
                <Form.Item
                    label="Gruppo"
                    name={["gruppo", "id"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select {...gruppoSelectProps} />
                </Form.Item>
                <Form.Item
                    label="Sedi"
                    name={["sedis"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select {...sediSelectProps}
                        mode={'multiple'}

                    />
                </Form.Item>
                <Form.Item
                    label="Confirmed"
                    name="confirmed"
                    valuePropName="checked"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Switch />
                </Form.Item>
            </Form>
        </Edit>
    );
};
