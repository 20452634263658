import {
    List,
    Table, useSelect, Select, Button
} from '@pankod/refine-antd';
import {IUser} from 'interfaces';
import moment from 'moment';
import {useList, useNotification} from '@pankod/refine-core';
import tableExport from 'antd-table-export';
import {useState} from 'react';
export const TimbratureList: React.FC = () => {
    const { open } = useNotification();
    const [month, setMonth] = useState<number>();
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [data, setData] = useState<any[]>();
    const timbrature = useList({
        resource: 'timbratures',
        queryOptions: {
            enabled: false
        },
        metaData: {
            populate: '*',
        },
        config: {
            pagination: {
              pageSize: 1000000
            },
            sort: [
                {
                    field: 'sedi.id',
                    order: 'asc'
                },
                {
                    field: 'user.id',
                    order: 'asc'
                }
            ],
            filters: [
                {
                    operator: 'or',
                    value: [
                        {
                            field: 'ingresso',
                            operator: 'between',
                            value: fromDate,
                        },
                        {
                            field: 'uscita',
                            operator: 'between',
                            value: fromDate
                        }
                    ]
                },
                {
                    operator: 'or',
                    value: [
                        {
                            field: 'ingresso',
                            operator: 'between',
                            value: toDate
                        },
                        {
                            field: 'uscita',
                            operator: 'between',
                            value: toDate
                        }
                    ]
                }
            ]
        }
    })


    const { selectProps: userSelectProps } = useSelect<IUser>({
        resource: "users",
        optionLabel: "username",
        optionValue: "id"
    });

    const scroll: { x?: number | string; y?: number | string } = {};
    scroll.y = 700;

    function groupBy(list: any, prop: any) {
        return list.reduce((groupped: any, item: any) => {
            let key = item[prop];
            delete item[prop];
            if (groupped.hasOwnProperty(key)) {
                groupped[key].push(item);
            } else {
                groupped[key] = [item];
            }
            return groupped
        }, {});
    }

    function groupSubKeys(obj: any, properties: any, propIndex: any) {
        let grouppedObj = groupBy(obj, properties[propIndex]);
        Object.keys(grouppedObj).forEach((key) => {
            if (propIndex < properties.length - 1) {
                grouppedObj[key] = groupSubKeys(grouppedObj[key], properties, propIndex + 1);
            } else {
                // grouppedObj[key] = grouppedObj[key].map((item: any) => item[properties[propIndex + 1]])
                grouppedObj[key] = grouppedObj[key].map((item: any) => new Date(item['ingresso']).getTime() + new Date(item['uscita']).getTime())
            }
        });
        return grouppedObj;
    }

    function groupByProperties(list: any, properties: any) {
        return groupSubKeys(list, properties, 0);
    }

    const handleSelect = (e: any) => {
        setMonth(e);
    };

    function calcoloOre() {
        if (!month) {
            open?.({
                type: 'error',
                message: 'Devi selezionare un Mese'
            });
        } else {
            const lastDayOfMonth = new Date(new Date().getFullYear(), Number(month), 0).getDate();
            setFromDate(new Date().getFullYear() + '-' + month + '-01T00:00:00.000Z');
            setToDate(new Date().getFullYear() + '-' + month + '-' + lastDayOfMonth + 'T23:59:59.000Z');
            setTimeout( () => {
                timbrature.refetch().then((res: any) => {
                    if (res && res.isSuccess && res.data.data && res.data.data.length > 0) {
                        const sed: any = [];
                        res.data?.data?.map( (s: any) => {
                            sed.push(
                                {
                                    key: s.id,
                                    utenteId: s.user.id,
                                    utente: s.user.nome + ' ' + s.user.cognome + '|' + s.user.matricola,
                                    matricola: s.user.matricola,
                                    sede: s.sedi?.nomeSede,
                                    sedeId: s.sedi?.id,
                                    ingresso: s.ingresso,
                                    uscita: s.uscita,
                                    movimento: new Date(s.createdAt).getDate()
                                });

                        })

                        const d = groupByProperties(sed, ['utente', 'sede', 'movimento']);
                        setTimeout(() => {
                            let result: any = [];
                            console.log(d);
                            let i = 0;
                            let uIndex = 0;
                            let sub = 0;
                            for (let r of Object.keys(d)) {
                                console.log(d)
                                for (let s of Object.keys(d[r])) {
                                    result.push(
                                        {
                                            key: Math.random(),
                                            utente: r.split('|')[0],
                                            sede: s,
                                            matricola: r.split('|')[1],
                                            tipo: 'Ore Lavorate'
                                        }
                                    )
                                    for (let t of Object.keys(d[r][s])) {
                                        // console.log(d[r][s][t]);
                                        const time = d[r][s][t][1] - d[r][s][t][0];
                                        const h = new Date(time).getHours();
                                        // console.log(h)
                                        result[i][t] = h;
                                    }
                                    i++;
                                }
                            }
                            setData(result);
                            console.log(result);
                        }, 1000)

                    }
                });
            }, 500)
        }


    }

    const columns = [
        {
            title: 'Matricola',
            dataIndex: 'matricola',
            key: 'matricola',
            width: 150
        },
        {
            title: 'Utente',
            dataIndex: 'utente',
            key: 'utente',
            width: 150
        },
        {
            title: 'Sede',
            dataIndex: 'sede',
            key: 'sede',
            width: 150
        },
        {
            title: 'Tipo',
            dataIndex: 'tipo',
            key: 'tipo',
            width: 150
        },
        {
            title: '1',
            dataIndex: '1',
            key: '1',
        },
        {
            title: '2',
            dataIndex: '2',
            key: '2',
        },
        {
            title: '3',
            dataIndex: '3',
            key: '3',
        },
        {
            title: '4',
            dataIndex: '4',
            key: '4',
        },
        {
            title: '5',
            dataIndex: '5',
            key: '5',
        },
        {
            title: '6',
            dataIndex: '6',
            key: '6',
        },
        {
            title: '7',
            dataIndex: '7',
            key: '7',
        },
        {
            title: '8',
            dataIndex: '8',
            key: '8',
        },
        {
            title: '9',
            dataIndex: '9',
            key: '9',
        },
        {
            title: '10',
            dataIndex: '10',
            key: '10',
        },
        {
            title: '11',
            dataIndex: '11',
            key: '11',
        },
        {
            title: '12',
            dataIndex: '12',
            key: '12',
        },
        {
            title: '13',
            dataIndex: '13',
            key: '13',
        },
        {
            title: '14',
            dataIndex: '14',
            key: '14',
        },

        {
            title: '15',
            dataIndex: '15',
            key: '15',
        },
        {
            title: '16',
            dataIndex: '16',
            key: '16',
        },
        {
            title: '17',
            dataIndex: '17',
            key: '17',
        },
        {
            title: '18',
            dataIndex: '18',
            key: '18',
        },
        {
            title: '19',
            dataIndex: '19',
            key: '19',
        },
        {
            title: '20',
            dataIndex: '20',
            key: '20',
        },
        {
            title: '21',
            dataIndex: '21',
            key: '21',
        },
        {
            title: '22',
            dataIndex: '22',
            key: '22',
        },
        {
            title: '23',
            dataIndex: '23',
            key: '23',
        },
        {
            title: '24',
            dataIndex: '24',
            key: '24',
        },
        {
            title: '25',
            dataIndex: '25',
            key: '25',
        },
        {
            title: '26',
            dataIndex: '26',
            key: '26',
        },
        {
            title: '27',
            dataIndex: '27',
            key: '27',
        },
        {
            title: '28',
            dataIndex: '28',
            key: '28',
        },
        {
            title: '29',
            dataIndex: '29',
            key: '29',
        },
        {
            title: '30',
            dataIndex: '30',
            key: '30',
        },
        {
            title: '31',
            dataIndex: '31',
            key: '31',
        }
    ];

    const dataExport = () => {
        console.log(data);
        // @ts-ignore
        const exportInstance = new tableExport(data, columns);
        exportInstance.download("Timbrature_" + moment(new Date()).format('DD_MM_YYYY'), 'xlsx')
    }

    return (
        <List headerButtons={<>
            <Select
                style={{ minWidth: 200 }}
                placeholder="Seleziona Mese"
                options={[
                    {
                        label: 'Gennaio',
                        value: '01'
                    },
                    {
                        label: 'Febbraio',
                        value: '02'
                    },
                    {
                        label: 'Marzo',
                        value: '03'
                    },
                    {
                        label: 'Aprile',
                        value: '04'
                    },
                    {
                        label: 'Maggio',
                        value: '05'
                    },
                    {
                        label: 'Giugno',
                        value: '06'
                    },
                    {
                        label: 'Luglio',
                        value: '07'
                    },
                    {
                        label: 'Agosto',
                        value: '08'
                    },
                    {
                        label: 'Settembre',
                        value: '09'
                    },
                    {
                        label: 'Ottobre',
                        value: '10'
                    },
                    {
                        label: 'Novembre',
                        value: '11'
                    },
                    {
                        label: 'Dicembre',
                        value: '12'
                    }
                ]}
                allowClear={true}
                value={month}
                onSelect={(e: any) => handleSelect(e)}
            />
            <Button type={"primary"} onClick={() => calcoloOre()}>Genera</Button>
            <Button type={"primary"} onClick={() => dataExport()}>Export CSV</Button>
        </>} title={
            <>
                Timbrature
            </>
        }>
            <Table dataSource={data} columns={columns} scroll={{x: '100vw', y: 740}}/>
        </List>
    );
};
