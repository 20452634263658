import {AuthProvider, useNotification} from '@pankod/refine-core';
import { AuthHelper } from "@pankod/refine-strapi-v4";

import { TOKEN_KEY, API_URL } from "./constants";

import axios from "axios";
import {notificationProvider} from '@pankod/refine-antd';

export const axiosInstance = axios.create();
const strapiAuthHelper = AuthHelper(API_URL + "/api");
export const authProvider: AuthProvider = {
  login: async ({ username, password }) => {
    const { data, status } = await axios
        .post(API_URL + "/api/auth/login", {
          identifier: username,
          password
        });
    if (status === 200 && data.user.gruppo.isAdmin) {
      localStorage.setItem(TOKEN_KEY, data.jwt);

      // set header axios instance
      axiosInstance.defaults.headers.common = {
        Authorization: `Bearer ${data.jwt}`,
      };

      return Promise.resolve();
    } else {
        notificationProvider.open?.({
          type: 'error',
          message: "Attenzione!",
          description: "Utente non Autorizzato!",
          key: "unique-id",
        });
    }
    // return Promise.reject();
  },
  logout: () => {
    localStorage.removeItem(TOKEN_KEY);
    return Promise.resolve();
  },
  checkError: () => Promise.resolve(),
  checkAuth: () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      axiosInstance.defaults.headers.common = {
        Authorization: `Bearer ${token}`,
      };
      return Promise.resolve();
    }

    return Promise.reject();
  },
  getPermissions: () => Promise.resolve(),
  getUserIdentity: async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (!token) {
      return Promise.reject();
    }

    const { data, status } = await strapiAuthHelper.me(token);
    if (status === 200) {
      const { id, username, email } = data;
      return Promise.resolve({
        id,
        username,
        email,
      });
    }

    return Promise.reject();
  },
};
