import {
    useForm,
    Form,
    Input,
    Select,
    Edit,
    Switch,
} from '@pankod/refine-antd';
import {ISedi} from 'interfaces';
import {GOOGLE_API} from '../../constants';
import {GoogleMap, Marker, useJsApiLoader, LoadScript, Autocomplete, StandaloneSearchBox} from '@react-google-maps/api';
import React, {useCallback, useEffect, useState} from 'react';

export const SediEdit: React.FC = () => {
    const { formProps, saveButtonProps, formLoading } = useForm<ISedi>({
        metaData: {
            populate: "*",
        },
    });

    const [refresh, setRefresh] = useState(false);
    const [state, setState] = useState({
        markers:
            {
                title: "The marker`s title will appear as a tooltip.",
                name: "SOMA",
                position: { lat: 0, lng: 0 }
            }

    });

    const [center, setCenter] = useState({
        lat: 42,
        lng: 13,
    });

    useEffect(() => {
        setState(previousState => {
            return {
                markers:
                    {
                        title: 'Indirizzo Scelto',
                        name: 'Nome',
                        position: { lat: Number(formProps.form?.getFieldValue('latitudine')), lng: Number(formProps.form?.getFieldValue('longitudine')) }
                    }
            };
        });
        setCenter({
            lat: Number(formProps.form?.getFieldValue('latitudine')),
            lng: Number(formProps.form?.getFieldValue('longitudine'))
        })
    }, [!formLoading])

    useEffect(() => {
        if (refresh) {
            setCenter({
                lat: Number(formProps.form?.getFieldValue('latitudine')),
                lng: Number(formProps.form?.getFieldValue('longitudine'))
            })
        }
        setRefresh(false);
    }, [refresh])

    const containerStyle = {
        width: '100%',
        height: '400px'
    };

    const [map, setMap] = useState<any>([]);
    const [search, setSearch] = useState<any>([]);


    const handleClick = (event: any) => {
        console.log(event);
        const lat = event.latLng.lat(), lng = event.latLng.lng();
        formProps.form?.setFieldsValue({
            'latitudine': lat.toString(),
            'longitudine': lng.toString()
        })
        setState({
                markers:
                    {
                        title: 'Indirizzo Scelto',
                        name: 'Nome',
                        position: { lat: Number(lat), lng: Number(lng) }
                    }
            }
        );
    }

    const onLoad = React.useCallback(function callback(maps: any) {
        const bounds = new window.google.maps.LatLngBounds(center);
        maps.fitBounds(bounds);
        setMap(maps)
    }, [])

    const onUnmount = React.useCallback(function callback(maps: any) {
        setMap(null)
    }, [])

    const onLoadS = (ref: any) => setSearch(ref);

    const onPlacesChanged = () => {
        formProps.form?.setFieldsValue({
            'latitudine': search.getPlaces()[0].geometry?.location?.lat().toString(),
            'longitudine': search.getPlaces()[0].geometry?.location?.lng().toString()
        })
        setState({
                markers:
                    {
                        title: 'Test',
                        name: 'Nome',
                        position: { lat: search.getPlaces()[0].geometry?.location?.lat(), lng: search.getPlaces()[0].geometry?.location?.lng() }
                    }
            }
        );
        setRefresh(true);
    };

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: GOOGLE_API,
        libraries: ['geometry', 'drawing', 'places'],
    });

    return (
        <Edit saveButtonProps={saveButtonProps} canDelete={false}>
            <Form {...formProps} layout="vertical">
                <Form.Item
                    label="Nome Sede"
                    name="nomeSede"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Indirizzo"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <div style={{ height: '45vh', width: '100%' }}>
                        {isLoaded &&
                            <GoogleMap
                                mapContainerStyle={containerStyle}
                                center={center}
                                zoom={13}
                                onClick={(e) => handleClick(e)}
                                onLoad={onLoad}
                                onUnmount={onUnmount}
                            >
                                <>
                                    <Marker
                                        key={Math.random()}
                                        title={state.markers.title}
                                        position={state.markers.position}
                                        draggable={true}
                                        onDragEnd={handleClick}

                                    />
                                </>
                                <StandaloneSearchBox
                                    onLoad={onLoadS}
                                    onPlacesChanged={onPlacesChanged}
                                >
                                    <input
                                        type="text"
                                        placeholder="Digita Indirizzo"
                                        style={{
                                            boxSizing: `border-box`,
                                            border: `1px solid transparent`,
                                            width: `240px`,
                                            height: `32px`,
                                            padding: `0 12px`,
                                            borderRadius: `3px`,
                                            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                                            fontSize: `14px`,
                                            outline: `none`,
                                            textOverflow: `ellipses`,
                                            position: "absolute",
                                            top: 10,
                                            left: "50%",
                                            marginLeft: "-120px"
                                        }}
                                    />
                                </StandaloneSearchBox>
                            </GoogleMap>
                        }
                    </div>
                </Form.Item>
                <Form.Item
                    label="Latitudine"
                    name="latitudine"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Longitudine"
                    name="longitudine"
                >
                    <Input />
                </Form.Item>
            </Form>
        </Edit>
    );
};
